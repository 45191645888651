<template>
    <div class="container">
        <div v-if="currentView === 'home'" class="menu">
            <div v-for="option in options" :key="option.name" class="menu-item" @mouseover="hoverImage = option.image"
                @mouseleave="hoverImage = ''" @click="navigateTo(option.view)">
                {{ option.name }}
            </div>
            <div v-if="hoverImage" class="hover-preview">
                <img :src="hoverImage" alt="Preview" />
            </div>
        </div>

        <div v-if="currentView === 'sala_pesi'" class="sala-pesi">
            <button class="back-button" @click="navigateTo('home')">← Torna Indietro</button>
            <a href="/checkout">
                <div class="info-box link">
                    <h2>
                        Iscriviti ora →
                    </h2>
                </div>
            </a>
            <div class="info-box">
                <h2 style="padding-bottom: 20px !important;">Orari</h2>
                <ul>
                    <li><strong>Lunedì - Venerdì:</strong> 7:00 - 22:00</li>
                    <li><strong>Sabato:</strong> 8:30 - 19:00</li>
                    <li><strong>Domenica:</strong> Chiuso</li>
                </ul>
            </div>
            <div class="info-box justify-left">
                <h2 style="padding-bottom: 20px !important;">Cosa comprende</h2>

                <h3><u>Rende</u></h3>
                <p>
                    La sala pesi è una sala di 600 m², in cui sono presenti più di 100 postazioni di allenamento,
                    comprensiva dei migliori macchinari sul mercato dei marchi come Panatta, Technogym, Hammer Strength
                    e Gym80.
                </p>

                <p>
                    La sala è provvista di impianto di areazione e climatizzazione, con sistemi che permettono il
                    ricircolo e la purificazione dell’aria.
                </p>

                <p>
                    La struttura consente, grazie ai nostri macchinari, l’allenamento sia ai principianti che agli
                    avanzati.
                </p>

                <h3><u>Montalto</u></h3>
                <p>
                    Centro Fitness esclusivo e all’avanguardia progettato all’interno di una moderna struttura.
                </p>

                <p>
                    Si sviluppa su un primo piano con vetrate e vista panoramica.
                </p>

                <p>
                    All’interno sono presenti 60 stazioni di allenamento, con macchinari Technogym ed Hammer Strength
                    NUOVI e di ultima generazione, come ad esempio i RUN con TV, Internet e possibilità di correre in
                    percorsi programmati nelle capitali del mondo (Barcellona, Rio de Janeiro, Roma, ecc.).
                </p>

                <p>
                    Avere nel centro macchinari così UNICI permette di svolgere allenamenti sia per chi ricerca canoni
                    estetici che per chi vuole il benessere psicofisico, la riabilitazione e la salute.
                </p>

            </div>
        </div>

        <div v-if="currentView === 'corsi'" class="corsi">
            <div class="container-fluid" style="padding-top: 20px; padding-bottom: 20px;">
                <button class="back-button" @click="navigateTo('home')">← Torna Indietro</button>
                <div class="grid">
                    <div v-for="course in courses" :key="course.id" class="course-item" @click="goToCheckout(course)">
                        <span class="course-title">{{ course.name }}</span>
                        <ul class="course-details">
                            <li v-for="time in course.timeSlots" v-bind:key="time.dayOfWeek">{{ tl(time.dayOfWeek) }}:
                                {{
                                    convertToHHMM(time.startTime) }} - {{ convertToHHMM(time.endTime) }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentView === 'personal_training'" class="sala-pesi">
            <button class="back-button" @click="navigateTo('home')">← Torna Indietro</button>
            <a href="/checkout?productId=676fea0ddc015ecc671bfd32">
                <div class="info-box link">
                    <h2>
                        Iscriviti ora →
                    </h2>
                </div>
            </a>
            <div class="info-box justify-left">
                <h2 style="padding-bottom: 20px !important;">Cosa comprende</h2>
                <br>
                Questo servizio consente di avere un Personal Trainer a disposizione con un’assistenza personalizzata a
                360° per tutta la durata dell’allenamento.
                <br><br>
                Il Personal Trainer farà con il tesserato una consulenza dalla durata di massimo un’ora, da stabilire
                previo appuntamento, durante la quale esaminerà lo storico allenante della persona, le problematiche
                fisiche che potrebbe avere, l’analisi della composizione corporea (che può essere approfondita tramite
                il servizio bia) ed i suoi obbiettivi a lungo termine per realizzare al meglio un piano di allenamento
                completo e funzionale.
                <br><br>
                Verrà poi compilato un Fit-Check sulla base degli argomenti trattati e sarà chiesta al tesserato la
                disponibilità di giorni ed orari per poter calendarizzare gli allenamenti.
                <br><br>
                Il personal trainer realizzerà quindi una programmazione di allenamento annuale e personalizzata in modo
                che sia perseguibile ed efficace per il tesserato e che porti gradualmente al raggiungimento degli
                obiettivi prefissati.
                <br><br>
                Nel corso dell’allenamento verrà utilizzato un cardiofrequenzimetro per il monitoraggio della frequenza
                cardiaca, così da personalizzare ulteriormente ogni seduta. Il Polar Verity Sense registrerà, inoltre,
                le calorie bruciate durante l’allenamento, dato che potrà essere inviato al nutrizionista nel caso in
                cui sia inserito nel pacchetto.
                <br><br>
                A fine allenamento il personal trainer invierà l’allenamento svolto, il link dell’allenamento registrato
                sul cardiofrequenzimetro ed il prossimo appuntamento concordato insieme.
                <br><br>
                Questo è un servizio AGGIUNTIVO all’abbonamento in sala pesi e consiste in un pacchetto da 10 sedute al
                costo di 300€ usufruibili entro un anno dalla sottoscrizione del servizio.
                <br><br>
                Si può effettuare il pagamento della seduta singola al costo di €50 (deve essere sempre attivo un
                abbonamento di sala pesi oppure un ingresso pagato singolarmente).
                <br><br>
                Inclusi nel servizio personal ci sono altre feature:
                <br><br>
                <ul>
                    <li>
                        acqua e caffè in omaggio in ogni seduta;
                    </li>
                    <li>
                        iscrizione annuale + assicurazione in omaggio;
                    </li>
                    <li>
                        box personal in omaggio, contenente una t-shirt, un telo e una borraccia.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentView: 'home',
            hoverImage: '',
            options: [
                { name: 'Sala pesi', view: 'sala_pesi', image: 'sala_pesi.jpg' },
                { name: 'Corsi', view: 'corsi', image: 'corsi.jpg' },
                { name: 'Personal Training', view: 'personal_training', image: 'pt.jpg' },
                { name: 'BIA', view: 'bia', image: ''}
            ],
            courses: []
        };
    },
    methods: {
        navigateTo(view) {
            this.currentView = view;
            if (view === 'corsi') {
                this.fetchCourses();
            }
            else if(view == 'bia'){
                window.location.href = 'https://account.elitefitness.it/checkout?productId=0e296747-8d6d-4197-9cff-0cd1f683df29';
            }
        },
        async fetchCourses() {
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/open/services'); // Sostituisci con l'API reale
                this.courses = await response.json();
                this.courses = this.courses.filter(course => course.type === 'COURSE');
            } catch (error) {
                console.error('Errore nel recupero dei corsi:', error);
            }
        },
        goToCheckout(course) {
            window.location.href = `/checkout?productId=${course.productId}`;
        },
        tl(day) {
            const days = {
                "MONDAY": "Lunedì",
                "TUESDAY": "Martedì",
                "WEDNESDAY": "Mercoledì",
                "THURSDAY": "Giovedì",
                "FRIDAY": "Venerdì",
                "SATURDAY": "Sabato",
                "SUNDAY": "Domenica"
            }
            return days[day];
        },
        convertToHHMM(timeString) {
            return timeString.split(':').slice(0, 2).join(':');
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #292D7D;
    color: white;
    padding: 0px;
    margin: 0px;
    min-height: 100vh;
    min-width: 100vw;
}

h1 {
    font-family: 'Michroma', sans-serif;
    font-weight: 700;
}

h2 {
    font-family: 'Michroma', sans-serif;
    font-weight: 700;
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.menu-item {
    border: 4px solid #e83539;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 700;
    font-size: 28px;
    /* Aumenta la dimensione del testo */
    transition: 0.3s;
    width: 500px;
    /* Imposta una larghezza fissa per i box */
    height: 130px;
    /* Imposta un'altezza fissa per i box */
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .menu-item {
        width: 100%;
    }
}

.menu-item:hover {
    background-color: #e8353880;
}

.hover-preview {
    position: absolute;
    top: 50px;
    right: 20px;
}

.hover-preview img {
    width: 200px;
    opacity: 0.5;
}

.info-box {
    border: 4px solid #e83539;
    margin: 20px auto;
    padding: 15px;
    width: 90%;
}

@media (min-width: 768px) {
    .info-box {
        width: 50%;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin-top: 20px;
}

.course-item {
    border: 4px solid #e83539;
    padding: 10px;
    font-size: 20px;
    padding: 25px;
    cursor: pointer;
}

.course-item:hover {
    background-color: #e83538;
}

.back-button {
    border: 0px;
    background-color: transparent;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 2px solid white;
}

.back-button:hover {
    border-bottom: 2px solid #e83539;
}

.justify-left {
    text-align: left;
    justify-content: left;
}

.info-box.link {
    cursor: pointer;
    text-decoration: none !important;
    color: white;
}

a {
    text-decoration: none;
}

.info-box.link:hover {
    background-color: #e83539;
}

.course-title {
    font-weight: 700;
    margin-bottom: 15px;
}

.course-details {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
}
</style>