<template>

    <div class="payment-wizard text-white"
        style="align-content: center; background-color: #292D7D; min-height: 100vh; padding: 20px;">
        <!-- Toast Container -->
        <div class="toast-container">
            <div v-for="(toast, index) in toasts" :key="index" :class="['toast', `toast-${toast.type}`, 'show']">
                {{ toast.message }}
            </div>
        </div>
        <div class="back-button" @click="$router.go(-1)"
            style="position: absolute; top: 10px; left: 10px; cursor: pointer; display: flex; align-items: center; gap: 10px;">
            <i class="fa-solid fa-chevron-left text-white" style="font-size: 32px;"></i>
            <span style="font-size: 1.2rem; font-family: 'Montserrat', sans-serif;">TORNA INDIETRO</span>
        </div>
        <div class="user-info-nav" v-if="userFullName" @click="toggleMenu">
            <div class="user-avatar"></div>
            <span class="user-name">{{ userFullName.replace(/\b\w/g, c => c.toUpperCase()) }}</span>
            <div v-show="menuVisible" class="dropdown-menu">
                <a href="/account" class="menu-item">Account</a>
                <a href="/logout" class="menu-item">Logout</a>
            </div>
        </div>
        <div class="container text-center" v-if="productNotFound">
            <h1>
                <i class="fa-solid fa-xmark"></i>
            </h1>
            <h3 class="fw-bold">Impossibile trovare il prodotto.</h3>
            <router-link to="https://elitefitness.it/app">
                <button class="btn btn-custom">Torna all'App</button>
            </router-link>
        </div>
        <div v-if="selectedProduct" class="container-fluid">
            <div class="row">
                <!-- Left column with steps -->
                <div class="col-12 col-xl-6 p-md-5">
                    <div class="step-container">
                        <!-- Selezione del trainer per BIA -->
                        <div v-if="showBIATrainerSelection" class="step active">
                            <div class="step-header">
                                <div class="step-circle">1</div>
                                <h2 class="step-title">SELEZIONA IL TRAINER</h2>
                            </div>
                            <div class="step-content">
                                <div v-if="selectedTrainerId" class="trainer-grid">
                                    <div class="trainer-option selected">
                                        <div class="trainer-title">
                                            {{ trainers.find(trainer => trainer.id == selectedTrainerId).fullName }}
                                        </div>
                                        <div class="checkmark">&#10004;</div>
                                    </div>
                                </div>
                            </div>
                            <div class="step-content" v-if="!selectedTrainerId">
                                <div class="trainer-grid">
                                    <div :class="['trainer-option', { selected: selectedTrainerId == trainer.id }]"
                                        @click="selectBIATrainer(trainer)" v-for="trainer in trainers"
                                        :key="trainer.id">
                                        <div class="trainer-title">{{ trainer.fullName }}</div>
                                        <div v-if="selectedTrainerId == trainer.id" class="checkmark">&#10004;</div>
                                    </div>
                                </div>
                                <div v-if="selectedTrainerId != null" class="text-center">
                                    <button @click="goToPayment" class="btn btn-custom mt-4">Avanti</button>
                                </div>
                            </div>

                        </div>
                        <!-- Step 1 -->
                        <div v-if="showVariationStep" class="step" :class="{ active: step === 1 }">
                            <div @click="goToStep1" class="step-header">
                                <div class="step-circle">1</div>
                                <h2 class="step-title">SELEZIONA IL PIANO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 1" class="step-content">
                                    <div class="duration-grid">
                                        <div v-for="variation in selectedProduct.variations"
                                            :key="variation.variationName" @click="selectVariation(variation)"
                                            :class="['duration-option', { selected: selectedVariationName === variation.variationName }]">
                                            <div class="duration-title">{{ variation.variationName }}</div>
                                            <div class="duration-price">{{ variation.price }}€</div>
                                            <div v-if="selectedVariationName === variation.variationName"
                                                class="checkmark">&#10004;</div>
                                        </div>
                                    </div>
                                    <div style="margin-top: 20px;" v-if="selectedProduct?.metadata?.freeTrial == true"
                                        @click="toggleFreeTrialMode"
                                        :class="['duration-option', { selected: freeTrial }]">
                                        <div class="duration-title">Prova Gratuita</div>
                                        <div class="duration-price">0€</div>
                                        <div v-if="freeTrial" class="checkmark">&#10004;</div>
                                    </div>
                                    <div class="checkbox-container"
                                        v-if="(selectedProduct?.metadata?.stopngo == true) && (!freeTrial)">
                                        <input type="checkbox" id="stopAndGo" class="custom-checkbox"
                                            v-model="requiresStopAndGo"
                                            @change="onStopAndGoChange($event.target.checked)" />
                                        <div class="text-container">
                                            <label for="stopAndGo" class="custom-label">STOP & GO</label>
                                            <p class="custom-description">Il servizio Stop & Go consente di sospendere
                                                temporaneamente il tuo abbonamento.</p>
                                        </div>
                                    </div>
                                    <label style="padding-top:20px;" v-if="productIsPersonal || productIsTarget" for="personalTrainerSelect" class="form-label  mt-2">Seleziona un
                                        Trainer</label>
                                    <select v-if="productIsPersonal" id="personalTrainerSelect" v-model="selectedPersonalTrainerId"
                                        class="form-select custom-select" :class="[{ 'form-select-selected': selectedPersonalTrainerId }]">
                                        <option v-for="trainer in trainers" :key="trainer.id" :value="trainer.id">
                                            {{ trainer.fullName }}
                                        </option>
                                    </select>
                                    <select v-if="productIsTarget" id="targetTrainerSelect" v-model="selectedTargetTrainerId"
                                        class="form-select custom-select" :class="[{ 'form-select-selected': selectedTargetTrainerId }]">
                                        <option v-for="trainer in trainers" :key="trainer.id" :value="trainer.id">
                                            {{ trainer.fullName }}
                                        </option>
                                    </select>
                                    <div v-if="!(freeTrial && userLogged)" class="text-center">
                                        <button @click="nextStep" class="btn btn-custom mt-4">Avanti</button>
                                    </div>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 2 -->
                        <div v-if="!userLogged" class="step" :class="{ active: step === 2 }">
                            <div class="step-header">
                                <div class="step-circle">{{ showVariationStep ? '2' : '1' }}</div>
                                <h2 class="step-title">I TUOI DATI</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 2" class="step-content">
                                    <form @submit.prevent="nextStep">
                                        <router-link
                                            :to="{ name: 'login', query: { productId: selectedProductId, action: 'checkout', variationName: selectedVariationName, discountCode: discountCode } }">
                                            <div class="btn white-container">
                                                Hai già un account? Accedi
                                            </div>
                                        </router-link>

                                        <div @click="toggleSection('personalInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2">
                                            <h4>
                                                <b>1.</b> Informazioni Personali
                                            </h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>

                                        <div v-if="!collapsedSections.personalInfo">
                                            <div class="form-group">
                                                <input v-model="user.firstName" name="firstName" type="text"
                                                    placeholder="Nome *" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.lastName" name="lastName" type="text"
                                                    placeholder="Cognome *" class="form-control" required />
                                            </div>
                                            <div class="form-group" style="margin-bottom: 15px;">
                                                <Datepicker name="dateOfBirth" v-model="user.dateOfBirth"
                                                    :format="format" :placeholder="'Data di nascita *'"
                                                    :enable-time-picker="false" :auto-apply="true" :teleport="true"
                                                    input-class-name="form-control" :preview-format="previewFormat"
                                                    @update:model-value="updateDateOfBirth">
                                                    <template #action-select>
                                                        <span class="action-select">Seleziona</span>
                                                    </template>
                                                </Datepicker>
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.placeOfBirth" name="placeOfBirth" type="text"
                                                    placeholder="Luogo di nascita (opzionale)" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.taxCode" name="taxCode" type="text"
                                                    placeholder="Codice Fiscale *" class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <GenderSelect v-model="user.gender"></GenderSelect>
                                            </div>
                                        </div>
                                        <div @click="toggleSection('contactInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4><b>2.</b> Recapiti</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-if="!collapsedSections.contactInfo">
                                            <div class="form-group">
                                                <input v-model="user.address" type="text"
                                                    placeholder="Indirizzo (opzionale)" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.city" type="text" placeholder="Città *"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.postalCode" type="text" placeholder="CAP *"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.province" type="text" placeholder="Provincia *"
                                                    class="form-control" required />
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.phoneNumber" type="text" placeholder="Telefono *"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div @click="toggleSection('accountInfo')"
                                            class="d-flex justify-content-between align-items-center mb-2 mt-5">
                                            <h4><b>3.</b> Crea un account</h4>
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </div>
                                        <div v-if="!collapsedSections.accountInfo">
                                            <div class="form-group">
                                                <input v-model="user.email" type="email" placeholder="Email *"
                                                    class="form-control" @blur="checkEmailExists"
                                                    :class="{ 'is-invalid': emailError }" required />
                                                <span v-if="emailError" class="text-danger">{{ emailError }}</span>
                                            </div>
                                            <div class="form-group">
                                                <input v-model="user.password"
                                                    :type="showPassword ? 'text' : 'password'" placeholder="Password *"
                                                    class="form-control" @blur="checkPassword"
                                                    :class="{ 'is-invalid': passwordError }" required />
                                                <button type="button" class="toggle-password"
                                                    @click="togglePasswordVisibility">
                                                    {{ showPassword ? 'Nascondi' : 'Mostra' }}
                                                </button>
                                            </div>
                                            <span v-if="passwordError" class="text-danger">{{ passwordError
                                                }}</span>
                                        </div>
                                        <p class="terms text-center">
                                            Accetto i termini e le <a class="text-white"
                                                href="https://elitefitness.it/condizioni-generali-contratto">condizioni
                                                generali di contratto</a>.<br>
                                            Confermo di aver preso visione dell'informativa relativa al <a
                                                class="text-white"
                                                href="https://elitefitness.it/diritto-di-recesso">diritto di
                                                recesso</a> e dell'informativa sulla <a class="text-white"
                                                href="https://elitefitness.it/privacy">Privacy</a>.
                                        </p>
                                        <div class="text-center mt-4">
                                            <button type="submit" class="btn btn-custom" :disabled="isLoading"
                                                :class="{ 'loading': isLoading }">
                                                <span v-if="!isLoading">
                                                    {{ collapsedSections.accountInfo === false ? 'Registrati' : 'Avanti'
                                                    }}
                                                </span>
                                                <span v-else>
                                                    <span class="loading-dots">
                                                        <span>.</span>
                                                        <span>.</span>
                                                        <span>.</span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </transition>
                        </div>

                        <!-- Step 3 -->
                        <div v-if="!freeTrial" class="step" :class="{ active: step === 3 }">
                            <div class="step-header">
                                <div class="step-circle">{{ (showVariationStep && !userLogged) ? '3' : '2' }}</div>
                                <h2 class="step-title">PAGAMENTO</h2>
                            </div>
                            <transition name="fade">
                                <div v-if="step === 3" class="step-content">
                                    <div class="payment-method-section">
                                        <h4>Metodo di pagamento</h4>
                                        <div class="payment-grid">
                                            <div v-for="method in paymentMethods" :key="method.value"
                                                @click="selectPaymentMethod(method)"
                                                :class="['payment-option', { selected: selectedPayment === method.value }]">
                                                <div class="payment-title">{{ method.label }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Messaggio per contanti -->
                                    <div class="payment-info" :class="[{ 'd-none': selectedPayment != 'CASH' }]">
                                        Disponibile dalle 9:00 alle 13:00
                                    </div>

                                    <!-- Stripe Credit Card Payment -->
                                    <div :class="[{ 'd-none': selectedPayment != 'CARD' }]">
                                        <h4>Carta di credito</h4>
                                        <div id="card-element" class="form-control mb-3"></div>
                                    </div>
                                    <!-- Bottone per Apple Pay e Google Pay -->
                                    <div :class="[{ 'd-none': selectedPayment != 'CARD' }]" style="padding-top:20px;">
                                        <div id="payment-request-button-container"></div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- Right column with overview -->
                <div class="col-12 col-xl-6 border-left-white border-top-white">
                    <div class="overview p-xl-5 p-sm-0">
                        <h2 class="fw-bold">PANORAMICA</h2>

                        <p>
                            Stai acquistando
                            <strong class="highlight">{{ selectedProduct.name }}</strong>
                            <span> presso </span>
                            <span>
                                <strong class="highlight">
                                    {{ centerDescription }}
                                </strong>
                            </span>
                            <span>.</span>
                        </p>

                        <div v-if="freeTrial">
                            <div v-if="selectedProduct.type == 'GYM'"
                                class="alert alert-primary d-flex align-items-center" role="alert">
                                <i class="fa-solid fa-circle-info"></i>
                                <span style="margin-left: 10px;">
                                    <div v-for="center in centers" :key="center.id" style="margin:10px">
                                        <span>L'ingresso per la prova gratuita nel centro </span>
                                        <span class="fw-bold">{{ center.name }}</span>
                                        <span>{{ generateFreeTierMessage(center) }}</span>
                                    </div>
                                </span>
                            </div>
                            <div v-else class="alert alert-primary d-flex align-items-center" role="alert">
                                <i class="fa-solid fa-circle-info"></i>
                                <span style="margin-left: 10px;">
                                    <div style="margin:10px">
                                        <span>L'ingresso per la prova gratuita del corso </span>
                                        <span>{{ generateFreeTierCourse(selectedService) }}</span>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div v-if="remainingCount != null" class="row">
                            <div v-if="remainingCount > 0" class="col">
                                <div class="alert alert-primary d-flex align-items-center" role="alert">
                                    <i class="fa-solid fa-circle-info"></i>
                                    <span style="margin-left: 10px;">Rimangono {{ remainingCount }} posti
                                        disponibili.</span>
                                </div>
                            </div>
                            <div v-if="remainingCount == 0" class="col">
                                <div class="alert alert-danger d-flex align-items-center" role="alert">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    <span style="margin-left: 10px;">Non ci sono posti disponibili.</span>
                                </div>
                            </div>
                        </div>

                        <!-- Variation Overview -->
                        <div v-if="selectedVariationName">

                            <div class="row">
                                <div class="col">
                                    <ul class="list-unstyled">
                                        <li>
                                            Durata:
                                        </li>
                                        <li>
                                            Quota di abbonamento:
                                        </li>
                                        <li
                                            v-if="selectedProduct?.metadata?.packEntry || selectedProduct?.type == 'ENTRY_PACK'">
                                            Ingressi:
                                        </li>

                                    </ul>
                                </div>
                                <div class="col">
                                    <ul class="list-unstyled text-end">
                                        <li>
                                            <span class="highlight">{{ selectedVariationName || 'N/A' }}</span>
                                        </li>
                                        <li>
                                            <span class="highlight">{{ (selectedVariation?.price).toFixed(2) || 'N/A'
                                                }}€</span>
                                        </li>
                                        <li
                                            v-if="selectedProduct?.metadata?.packEntry || selectedProduct?.type == 'ENTRY_PACK'">
                                            <span class="highlight"> {{ entryPeriod || 'N/A' }}</span>
                                        </li>
                                        <li v-else>
                                            <span class="highlight">fra {{ renewalPeriod || 'N/A' }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <ul class="list-unstyled">
                                        <li :class="[{ strike: annualVariation }]" v-if="requiresRegistration">
                                            Iscrizione
                                            ed assicurazione:
                                        </li>
                                        <li v-if="requiresMembership">Tesseramento:</li>
                                        <li v-if="requiresStopAndGo">Stop & Go:</li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <ul class="list-unstyled text-end">
                                        <li v-if="requiresRegistration">
                                            <span :class="[{ strike: annualVariation }]" class="highlight">{{
                                                registrationProduct?.price.toFixed(2) }}€</span>
                                        </li>
                                        <li v-if="requiresMembership">
                                            <span class="highlight">{{ membershipProduct?.price.toFixed(2) }}€</span>
                                        </li>
                                        <li v-if="requiresStopAndGo">
                                            <span class="highlight">{{ selectedStopAndGoVariation?.price.toFixed(2)
                                                }}€</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Mandatory Service if present -->
                            <div v-if="requiresMandatoryUpsell && mandatoryProduct!=null"
                                class="row justify-content-between mb-4 upsell-box mandatory-upsell-box">
                                <div class="col-8">
                                    <div class="upsell-checkbox-container">
                                        <input type="checkbox" id="stopAndGo" class="custom-checkbox" checked
                                            disabled />
                                        <div class="text-container">
                                            <label for="stopAndGo" class="custom-label ">{{ mandatoryProduct.name
                                                }}</label>
                                            <p class="custom-description d-none d-sm-block">Questo servizio è
                                                obbligatorio per il piano scelto.</p>
                                            <label
                                                v-if="this.mandatoryProduct.variations && this.mandatoryProduct.variations.length > 0"
                                                for="variationSelect" class="form-label  mt-2">Seleziona una
                                                variazione</label>
                                            <select
                                                v-if="this.mandatoryProduct.variations && this.mandatoryProduct.variations.length > 0"
                                                id="variationSelect" v-model="selectedMandatoryVariation"
                                                class="form-select custom-select">
                                                <option
                                                    v-for="variation in this.mandatoryProduct.variations.filter(v => v.active ===true)"
                                                    :key="variation.variationName" :value="variation">
                                                    {{ variation.variationName }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedMandatoryVariation"
                                    class="col-4 d-flex align-items-center justify-content-end">
                                    <span class="highlight">
                                        {{ selectedMandatoryVariation?.price }}€
                                    </span>
                                </div>
                            </div>

                            <div v-if="biaUpsellEnabled" class="row justify-content-between mb-4 upsell-box">
                                <div class="col-8">
                                    <div class="upsell-checkbox-container">
                                        <input type="checkbox" id="stopAndGo" class="custom-checkbox"
                                            v-model="requiresBIA" @change="onBIAUpsell($event.target.checked)" />
                                        <div class="text-container">
                                            <label for="stopAndGo" class="custom-label ">BIA</label>
                                            <p class="custom-description d-none d-sm-block">Il servizio BIA ti permette
                                                di monitorare e
                                                analizzare la composizione corporea in modo rapido e preciso.</p>
                                            <label v-if="requiresBIA" for="trainerSelect"
                                                class="form-label  mt-2">Seleziona un
                                                Trainer</label>
                                            <select v-if="requiresBIA" id="trainerSelect" v-model="selectedTrainerId"
                                                class="form-select custom-select">
                                                <option v-for="trainer in trainers" :key="trainer.id"
                                                    :value="trainer.id">
                                                    {{ trainer.fullName }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-4 d-flex align-items-center justify-content-end">
                                    <span class="highlight">
                                        {{ biaProduct?.price.toFixed(2) }}€
                                    </span>
                                </div>
                            </div>

                            <div class="row" v-if="selectedVariationPrice.discount > 0">
                                <div class="col">
                                    <h5 class="mt-5 fw-bold">Sconto applicato</h5>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">-{{ (selectedVariationPrice.discount).toFixed(2)
                                        || 'N/A' }}€</h4>
                                </div>
                            </div>
                            <div class="row" v-if="selectedVariationPrice.discount > 0 && promoCode">
                                <div class="col-4 ms-auto">
                                    <ul style="list-style-type: none; padding: 0;">
                                        <li class="discount-item-description">
                                            <span>{{ promoCode.discountAmount }}{{ promoCode.percentage ? '%' : '€' }}
                                                sul
                                                prodotto</span>
                                        </li>
                                        <li class="discount-item-description"
                                            v-if="promoCode.upsellsDiscount.applyToMembership && requiresMembership">
                                            <span>{{ promoCode.upsellsDiscount.membershipDiscount }}% sul
                                                Tesseramento</span>
                                        </li>
                                        <li class="discount-item-description"
                                            v-if="promoCode.upsellsDiscount.applyToRegistration && requiresRegistration">
                                            <span>{{ promoCode.upsellsDiscount.registrationDiscount }}%
                                                sull'Iscrizione</span>
                                        </li>
                                        <li class="discount-item-description"
                                            v-if="promoCode.upsellsDiscount.applyToBIA && requiresBIA">
                                            <span>{{ promoCode.upsellsDiscount.biaDiscount }}% sulla BIA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-1 fw-bold">TOTALE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-1 fw-bold text-end">{{ (selectedVariationPrice.total).toFixed(2) ||
                                        'N/A' }}€</h4>
                                </div>
                            </div>

                        </div>

                        <!-- Product Overview -->
                        <div v-if="!showVariationStep">
                            <div class="row">
                                <div class="col">
                                    <h4 class="mt-5 fw-bold">TOTALE</h4>
                                </div>
                                <div class="col">
                                    <h4 class="mt-5 fw-bold text-end">{{ (selectedVariationPrice.total).toFixed(2) ||
                                        'N/A' }}€</h4>
                                </div>
                            </div>

                        </div>

                        <p class="terms">
                            Confermo di aver preso visione ed accetto il <a
                                href="https://elitefitness.it/regulation/">regolamento di
                                Elite Fitness SSD</a>.<br>
                            Confermo di aver preso visione dell'informativa sulla <a
                                href="https://elitefitness.it/privacy/">Privacy</a>.
                        </p>
                        <div v-if="(selectedVariationName || !showVariationStep) && userLogged" class="mt-3">
                            <div class="input-group mb-3">
                                <input type="text" v-model="discountCode"
                                    class="form-control discount custom-placeholder"
                                    placeholder="INSERISCI IL CODICE SCONTO" aria-label="Codice Sconto"
                                    aria-describedby="button-addon2">
                                <button class="btn btn-danger rounded-0" type="button"
                                    @click="applyDiscount">APPLICA</button>
                            </div>
                        </div>
                        <div v-else-if="!userLogged && discountCode?.length > 0">
                            <div class="alert alert-danger d-flex align-items-center" role="alert">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                <span style="margin-left: 10px;">Per applicare un codice sconto, devi
                                    <router-link
                                        :to="{ name: 'login', query: { productId: selectedProductId, action: 'checkout', variationName: selectedVariationName, discountCode: discountCode } }">
                                        <a style="color:white" href="#">effettuare l'accesso</a>
                                    </router-link>
                                </span>
                            </div>
                        </div>
                        <div class="text-center mt-4">
                            <button :disabled="!enablePaymentButton" @click="handlePayment"
                                class="btn btn-custom complete-payment-btn text-nowrap">
                                <span v-if="!freeTrial">COMPLETA IL PAGAMENTO</span>
                                <span v-else>INIZIA LA PROVA GRATUITA</span>
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import GenderSelect from '@/components/GenderSelect.vue';
import Datepicker from '@vuepic/vue-datepicker'
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

export default {
    components: {
        GenderSelect,
        Datepicker
    },
    setup() {
        const route = useRoute();

        onMounted(() => {
            console.log("Route object after mount:", route);
            console.log("Product ID from URL after mount:", route.query?.productId);
        });

        return {};
    },
    data() {
        return {
            isLoading: false,
            menuVisible: false,
            userLogged: false,
            userEmail: null,
            userToken: null,
            userFullName: null,
            userId: null,

            step: 1,  // Step iniziale
            selectedVariationName: null,  // Variazione selezionata
            selectedProductId: null,  // Imposta l'id del prodotto specifico qui
            selectedProduct: null,
            selectedVariation: null,
            annualVariation: false,

            productNotFound: false,

            hasVariations: false,  // Indica se il prodotto ha variazioni
            showVariationStep: false,  // Controlla se lo step delle variazioni deve essere mostrato

            user: {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                placeOfBirth: '',
                taxCode: '',
                gender: '',
                address: '',
                city: '',
                postalCode: '',
                province: '',
                phoneNumber: '',
                mobileNumber: ''
            },
            stripe: null,
            elements: null,
            cardElement: null,
            paymentRequestButton: null,
            paymentRequest: null,
            isPaymentReady: false,

            promoCode: null,
            discountCode: '',  // Codice sconto inserito dall'utente
            discountAmount: 0,  // Importo dello sconto
            discountError: null,  // Messaggio di errore per il codice sconto
            finalPrice: 0,  // Prezzo finale dopo lo sconto

            emailError: null,

            requiresRegistration: true,
            requiresMembership: true,
            requiresStopAndGo: false,
            registrationProduct: null, // Prodotto di iscrizione e assicurazione
            membershipProduct: null, // Prodotto di tesseramento
            stopngoProduct: null, // Prodotto di Stop & Go
            biaProduct: null,

            selectedStopAndGoVariation: null,

            paymentMethods: [
                { label: "Carta", value: "CARD" },
                { label: "Contanti", value: "CASH" },
            ],
            selectedPayment: "CARD", // Default su Carta
            format: 'dd/MM/yyyy',
            previewFormat: (date) => {
                if (!date) return ''
                const day = date.getDate().toString().padStart(2, '0')
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const year = date.getFullYear()
                return `${day}/${month}/${year}`
            },
            showPassword: false,
            passwordError: null,

            // Registration Steps
            collapsedSections: {
                personalInfo: false,
                contactInfo: true,
                accountInfo: true,
            },

            centers: [],
            selectedService: null,
            toasts: [],

            remainingCount: null,

            freeTrial: false, // Se true, il checkout deve effettuare un free trial. Non verifica se il prodotto ha un free trial
            requiresBIA: false,
            selectedTrainerId: null,
            trainers: [],
            showBIATrainerSelection: false,

            // Mandatory Product Upsell
            requiresMandatoryUpsell: true,
            mandatoryProduct: null,
            mandatoryService: null,
            selectedMandatoryVariation: null,

            // Selezione del personal trainer per il prodotto PERSONAL_TRAINING
            selectedPersonalTrainerId: null,
            selectedTargetTrainerId: null,

        };
    },
    async created() {
        try {
            // Inizializza Stripe
            this.stripe = await loadStripe('pk_live_51QP1pFKXs4KZv5huj0vF3RTtJSglnwXuYgauIG0tpIlD7zHxkGxL7Xh5gPHjrfLXjtLV13WjSPzAScy2BrVppoSc00zBtu1kK1');
            if (!this.stripe) {
                throw new Error('Stripe failed to initialize');
            }

            // Verifica se l'utente è loggato
            var email = localStorage.getItem('userEmail');
            var token = localStorage.getItem('userToken');

            // Verifica se c'è il token da URL
            const userTokenFromUrl = this.$route.query?.userToken;
            if (userTokenFromUrl) {
                const response = await fetch('https://elitefitness.utopialabs.it/api/users/profile', {
                    headers: {
                        Authorization: `Bearer ${userTokenFromUrl}`,
                    },
                });
                if(response.ok) {
                    const userData = await response.json();
                    this.userLogged = true;
                    this.userEmail = userData.email;
                    this.userToken = userTokenFromUrl;
                    this.userFullName = userData.personalInfo.fullName.replace(/\b\w/g, c => c.toUpperCase());
                    this.userId = userData.id;
                    email = this.userEmail;
                    token = this.userToken;
                }
            }

            // Recupera il profilo utente se l'utente è loggato
            if (email && token) {
                this.userLogged = true;
                this.userEmail = email;
                this.userToken = token;

                try {
                    // Recupera il profilo utente
                    const response = await fetch('https://elitefitness.utopialabs.it/api/users/profile', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        this.userLogged = false;
                        this.userEmail = null;
                        this.userToken = null;
                        localStorage.removeItem('userEmail');
                        localStorage.removeItem('userToken');
                        localStorage.removeItem('tokenExpiration');
                        console.error('Errore nel recupero del profilo utente');
                        this.$router.go();
                        return;
                    }

                    const userData = await response.json();
                    this.userFullName = userData.personalInfo.fullName.replace(/\b\w/g, c => c.toUpperCase());
                    this.userId = userData.id;
                } catch (error) {
                    console.error('Errore durante la richiesta al server:', error);
                }
            }

            const freeTrial = this.$route.query?.freeTrial;
            this.freeTrial = freeTrial === 'true';

            const productIdFromUrl = this.$route.query?.productId;
            const variationFromUrl = this.$route.query?.variationName;
            const discountCodeFromUrl = this.$route.query?.discountCode;
            if (this.discountCode) {
                this.discountCode = discountCodeFromUrl;
            }
            else {
                this.discountCode = '';
            }
            let productResponse;

            // Se è presente un ID prodotto nell'URL, recupera quel prodotto specifico
            if (productIdFromUrl) {
                productResponse = await fetch(`https://elitefitness.utopialabs.it/api/products/${productIdFromUrl}`);
                if (productResponse.ok) {
                    const product = await productResponse.json();
                    this.selectedProduct = product;

                    this.showBIATrainerSelection = this.selectedProduct.type == 'BIA';

                    // Filtro per verificare se this.selectedProduct.variations[].active è true
                    if (this.selectedProduct.variations) {
                        this.selectedProduct.variations = this.selectedProduct.variations.filter(v => v.active === true);
                    }

                    // Verifico se il variationFromUrl è presente e se è una variazione attiva
                    if (variationFromUrl) {
                        const selectedVariation = this.selectedProduct.variations.find(v => v.variationName === variationFromUrl);
                        if (selectedVariation) {
                            this.selectedVariation = selectedVariation;
                            this.selectedVariationName = this.selectedVariation.variationName;
                        }
                    }
                    // Seleziono la variazione usando selectVariation(..)

                    // Verifico se il discountCode è presente e se è una promo attiva
                    if (this.discountCode) {
                        this.applyDiscount();
                    }

                    this.selectedProductId = product.id;

                    if (this.selectedProduct.type === 'COURSE') {
                        await this.fetchRemainingCount(this.selectedProduct.serviceId);
                    }

                } else {
                    this.productNotFound = true;
                }
            } else {
                // Continua con il funzionamento attuale
                productResponse = await fetch('https://elitefitness.utopialabs.it/api/products');
                if (productResponse.ok) {
                    const products = await productResponse.json();
                    this.selectedProduct = products.find((p) => p.name.includes("Fitness Lover"));

                    // Filtro per verificare se this.selectedProduct.variations[].active è true
                    if (this.selectedProduct.variations) {
                        this.selectedProduct.variations = this.selectedProduct.variations.filter(v => v.active === true);
                    }

                    if (this.selectedProduct)
                        this.selectedProductId = this.selectedProduct.id;
                    else this.productNotFound = true;

                    if (variationFromUrl) {
                        const selectedVariation = this.selectedProduct.variations.find(v => v.variationName === variationFromUrl);
                        if (selectedVariation) {
                            this.selectedVariation = selectedVariation;
                            this.selectedVariationName = this.selectedVariation.variationName;
                        }
                    }

                    if (this.discountCode) {
                        this.applyDiscount();
                    }
                }
            }

            if (this.selectedProduct && this.selectedProduct.serviceId)
                this.fetchServiceById(this.selectedProduct.serviceId);

            // Controlla se il prodotto ha variazioni
            if ((this.selectedProduct.type === 'GYM' ||
                this.selectedProduct.type === 'COURSE')
                && this.selectedProduct.variations && this.selectedProduct.variations.length > 0) {
                this.hasVariations = true;
                this.showVariationStep = true;


            } else {
                // Non ci sono variazioni o il prodotto non è di tipo GYM o COURSE
                this.hasVariations = false;
                this.showVariationStep = false;
                this.finalPrice = this.selectedProduct.price;  // Usa il prezzo del prodotto
                this.step = 1; // Salta direttamente allo step successivo
            }

            // Verifica se l'utente è loggato per mostrare iscrizione e tesseramento in upsell
            if (this.userLogged) {
                // Fetch stato dei constraints utente solo se loggato
                const constraintsResponse = await fetch('https://elitefitness.utopialabs.it/api/users/constraints', {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                    },
                });
                if (constraintsResponse.ok) {
                    const constraints = await constraintsResponse.json();
                    this.requiresRegistration = !constraints.registrationInsurance;
                    this.requiresMembership = !constraints.membershipCard;

                    if (this.requiresRegistration) {
                        const registrationProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae517');
                        if (registrationProductResponse.ok) {
                            this.registrationProduct = await registrationProductResponse.json();
                        }
                    }

                    if (this.requiresMembership) {
                        const membershipProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae518');
                        if (membershipProductResponse.ok) {
                            this.membershipProduct = await membershipProductResponse.json();
                        }
                    }

                    const stopngoProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/67404798180f654c79a1286b');
                    if (stopngoProductResponse.ok) {
                        this.stopngoProduct = await stopngoProductResponse.json();
                    }

                } else {
                    console.error('Failed to fetch user constraints');
                }
            } else {
                // Utente non loggato: imposta di default registrazione e tesseramento
                this.requiresRegistration = true;
                this.requiresMembership = true;

                // Recupera i prodotti di registrazione e tesseramento
                const registrationProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae517');
                if (registrationProductResponse.ok) {
                    this.registrationProduct = await registrationProductResponse.json();
                }

                const membershipProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/672cdc7b078175c5932ae518');
                if (membershipProductResponse.ok) {
                    this.membershipProduct = await membershipProductResponse.json();
                }

                const stopngoProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/67404798180f654c79a1286b');
                if (stopngoProductResponse.ok) {
                    this.stopngoProduct = await stopngoProductResponse.json();
                }
            }

            const biaProductResponse = await fetch('https://elitefitness.utopialabs.it/api/products/0e296747-8d6d-4197-9cff-0cd1f683df29');
            if (biaProductResponse.ok) {
                this.biaProduct = await biaProductResponse.json();
                console.log(this.biaProduct?.price);
            }

            // Recupera i centri
            const centersResponse = await fetch('https://elitefitness.utopialabs.it/api/open/centers');
            if (centersResponse.ok) {
                this.centers = [...(await centersResponse.json())];
                console.log("Centri recuperati:", this.centers);
            } else {
                console.error("Errore durante il recupero dei centri:", centersResponse.status);
            }

            // Recupera i trainer
            const trainersResponse = await fetch('https://elitefitness.utopialabs.it/api/open/trainer');
            if (trainersResponse.ok) {
                this.trainers = await trainersResponse.json();
                console.log("Trainer recuperati:", this.trainers);
            } else {
                console.error("Errore durante il recupero dei trainer:", trainersResponse.status);
            }

            // Verifica se il prodotto è di tipo SERVICE e ha un parentServiceId
            console.log('Selected Product: ',this.selectedProduct);  
            if ((this.selectedProduct.type === 'GYM' ||
            this.selectedProduct.type === 'COURSE') && this.selectedProduct.serviceId) {
                const parentServiceId = this.selectedService.parentServiceId;

                // Verifica se l'utente ha già il servizio obbligatorio
                const hasSubscription = await fetch(`https://elitefitness.utopialabs.it/api/subscriptions/has/${parentServiceId}`, {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                    },
                });

                const hasSubscriptionResponse = await hasSubscription.json();
                console.log(hasSubscriptionResponse);
                this.requiresMandatoryUpsell = hasSubscription.ok ? (hasSubscriptionResponse.hasSubscription==false) : true;

                // Recupera il servizio obbligatorio
                const serviceResponse = await fetch(`https://elitefitness.utopialabs.it/api/open/services/${parentServiceId}`);
                this.mandatoryService = serviceResponse.ok ? await serviceResponse.json() : null;
                
                // Recupera il prodotto obbligatorio
                productResponse = await fetch('https://elitefitness.utopialabs.it/api/products');
                const products = await productResponse.json();
                this.mandatoryProduct = products.find((p) => p.serviceId == parentServiceId);
            }

        } catch (error) {
            console.error('Error initializing PaymentComponent:', error);
        }
    },


    computed: {
        productIsPersonal(){
            return this.selectedProduct?.metadata?.type=='PERSONAL_TRAINING';
        },
        productIsTarget(){
            return this.selectedProduct?.metadata?.type=='TARGET_TRAINING';
        },
        biaUpsellEnabled(){
            return this.selectedService?.bia;
        },
        enablePaymentButton() {
            if (this.userLogged) {
                if (this.freeTrial) {
                    return true;
                }
                if (this.requiresBIA && !this.selectedTrainerId) {
                    return false;
                }
                if(this.requiresMandatoryUpsell && !this.selectedMandatoryVariation){
                    return false;
                }
                return this.isPaymentReady && this.remainingCount != 0;
            }
            return false;
        },
        centerDescription() {
            // Se i dati non sono ancora caricati
            if (!this.selectedService || !this.centers.length) {
                return "tutti i centri";
            }

            // Assicurati di avere sempre un array di centri associati
            const serviceCenters = Array.isArray(this.selectedService.centersId)
                ? this.selectedService.centersId.map(id => id.toString())
                : [];

            // Crea un array con gli ID di tutti i centri fetchati (normalizzati a stringhe)
            const allCenterIds = this.centers.map(center => center.id.toString());

            console.log(allCenterIds);
            console.log(serviceCenters);

            // Se il numero di centri associati è uguale al numero totale di centri, restituisci "tutti i centri"
            if (serviceCenters.length === allCenterIds.length) {
                return "tutti i centri";
            } else {
                // Altrimenti, filtra i centri fetchati e prendi quelli associati
                const associatedCenters = this.centers
                    .filter(center => serviceCenters.includes(center.id.toString()))
                    .map(center => center.name);
                return associatedCenters.join(", ");
            }
        },
        selectedVariationPrice() {
            if (!this.selectedProduct) return { total: 0, discount: 0 };

            let mandatoryUpsell = 0;
            if(this.requiresMandatoryUpsell){
                mandatoryUpsell = this.selectedMandatoryVariation?.price || (this.mandatoryProduct?.price || 0);
            }

            if (this.selectedProduct?.variations) {
                // Trova la variazione selezionata del prodotto principale
                const variation = this.selectedProduct.variations.find(
                    variation => variation.variationName === this.selectedVariationName
                );

                // Calcola il prezzo totale con le variazioni
                let regFee = (!this.annualVariation && this.requiresRegistration && this.registrationProduct?.price) ? this.registrationProduct.price : 0;
                let membFee = this.requiresMembership && this.membershipProduct?.price ? this.membershipProduct.price : 0;
                let stopAndGoPrice = this.requiresStopAndGo ? (this.selectedStopAndGoVariation?.price || 0) : 0;

                let biaPrice = this.requiresBIA ? (this.biaProduct?.price ? this.biaProduct.price : 0) : 0;

                let productPrice = variation?.price || 0;

                let discountAmount = 0;

                if (this.promoCode && this.promoCode.upsellsDiscount) {
                    if (this.promoCode.upsellsDiscount.applyToRegistration) {
                        const regDiscount = (this.promoCode.upsellsDiscount.registrationDiscount / 100) * regFee;
                        regFee = regFee - regDiscount;
                        discountAmount += regDiscount;
                    }
                    if (this.promoCode.upsellsDiscount.applyToMembership) {
                        const membDiscount = (this.promoCode.upsellsDiscount.membershipDiscount / 100) * membFee;
                        membFee = membFee - membDiscount;
                        discountAmount += membDiscount;
                    }
                    if (this.promoCode.upsellsDiscount.applyToBIA) {
                        const biaDiscount = (this.promoCode.upsellsDiscount.biaDiscount / 100) * biaPrice;
                        biaPrice = biaPrice - biaDiscount;
                        discountAmount += biaDiscount;
                    }

                    if (this.promoCode.percentage) {
                        const productDiscount = (this.promoCode.discountAmount / 100) * (variation?.price || 0);
                        productPrice = (variation?.price || 0) - productDiscount;
                        discountAmount += productDiscount;
                    } else {
                        productPrice = (variation?.price || 0) - this.promoCode.discountAmount;
                        discountAmount += this.promoCode.discountAmount;
                    }
                }

                // Ritorna il prezzo totale
                return {
                    total: productPrice ? (productPrice + regFee + membFee + stopAndGoPrice + biaPrice + mandatoryUpsell) : 0,
                    discount: discountAmount,
                };

            } else {
                // Nessuna variazione
                let productPrice = this.selectedProduct?.price || 0;
                let discountAmount = 0;

                if (this.promoCode && this.promoCode.upsellsDiscount) {
                    if (this.promoCode.percentage) {
                        const productDiscount = (this.promoCode.discountAmount / 100) * (productPrice || 0);
                        productPrice = productPrice - productDiscount;
                        discountAmount += productDiscount;
                    } else {
                        productPrice = (productPrice || 0) - this.promoCode.discountAmount;
                        discountAmount += this.promoCode.discountAmount;
                    }
                }

                return {
                    total: productPrice,
                    discount: discountAmount
                }
            }
        },
        entryPeriod(){
            const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
            return variation ? variation.attributes.duration + ' ingressi' : null;
        },
        renewalPeriod() {
            const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
            return variation ? variation.attributes.duration + ' giorni' : null;
        },

        getDuration() {
            const variation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
            return variation ? variation.attributes.duration : null;
        }
    },
    watch: {
        centers: {
            handler(newVal) {
                console.log("centers aggiornato:", newVal);
            },
            deep: true
        },
        selectedService: {
            handler(newVal) {
                console.log("selectedService aggiornato:", newVal);
            },
            deep: true
        }
    },
    methods: {
        async fetchRemainingCount(serviceId) {
            try {
                const response = await fetch(`https://elitefitness.utopialabs.it/api/open/subscriptions/course/${serviceId}/count/remaining`);
                const data = await response.json();
                this.remainingCount = data.count;
            } catch (error) {
                console.error('Error fetching remaining count:', error);
            }
        },
        goToStep1() {
            this.step = 1;
        },
        onStopAndGoChange(newValue) {
            this.requiresStopAndGo = newValue; // Aggiorna lo stato di Stop and Go
            this.resetDiscountCode(); // Resetta il codice sconto
        },
        onBIAUpsell(newValue) {
            this.requiresBIA = newValue; // Aggiorna lo stato di Stop and Go
            this.resetDiscountCode(); // Resetta il codice sconto
        },
        resetDiscountCode() {
            // Svuota il campo del codice sconto

            // Resetta eventuali valori legati allo sconto
            this.discountAmount = 0;
            this.discountError = null;

            // Ripristina il prezzo originale della variazione selezionata
            this.finalPrice = this.selectedVariationPrice.total;

            console.log('Codice sconto resettato e finalPrice aggiornato:', this.finalPrice);
        },
        showToast(message, type = 'info') {
            const toast = { message, type };
            this.toasts.push(toast);
            console.log("add toast", toast);
            setTimeout(() => {
                this.toasts.splice(this.toasts.indexOf(toast), 1);
            }, 3000);
        },
        areAllCentersIncluded(serviceCenters, allCenters) {
            // Controlla se tutti i centri del servizio sono inclusi nei centri fetchati
            for (const centerId of serviceCenters) {
                if (!allCenters.has(centerId)) {
                    return false;
                }
            }
            return true;
        },
        fetchServiceById(serviceId) {
            return fetch(`https://elitefitness.utopialabs.it/api/open/services/${serviceId}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(`Errore durante il recupero del servizio ${serviceId}: ${response.status}`);
                    }
                })
                .then(service => {
                    this.selectedService = service;
                    console.log("Servizio recuperato:", this.selectedService);
                })
                .catch(error => {
                    console.error(`Errore nella fetch del servizio ${serviceId}:`, error);
                });
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        checkPassword() {
            if (!this.validatePassword(this.user.password)) {
                this.passwordError = "La password deve essere lunga tra 8 e 100 caratteri e contenere almeno un numero, una lettera maiuscola, una lettera minuscola, un carattere speciale e nessuno spazio.";
            } else {
                this.passwordError = null;
            }
        },
        validatePassword(password) {
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordPattern.test(password);
        },
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
        },
        clearUserData() {
            localStorage.removeItem('user');
        },
        saveUserData() {
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async checkEmailExists() {
            try {
                if (!this.user.email) {
                    this.emailError = 'Inserisci un\'email valida.';
                    return;
                }

                const response = await fetch(`https://elitefitness.utopialabs.it/api/users/exists?email=${encodeURIComponent(this.user.email)}`);

                if (!response.ok) {
                    throw new Error('Errore nella verifica dell\'email');
                }

                const result = await response.json();

                // Verifica il risultato: se l'email esiste, mostra un errore
                if (result.exists) {
                    this.emailError = 'Questa email è già registrata.';
                } else {
                    this.emailError = null; // Nessun errore, email non esistente
                }
            } catch (error) {
                console.error('Errore nella verifica dell\'email:', error);
                this.emailError = 'Impossibile verificare l\'email. Riprova più tardi.';
            }
        },
        async applyDiscount() {

            if (!this.userLogged) {
                this.discountError = 'Devi essere loggato per applicare un codice sconto.';
                return;
            }


            if (!this.discountCode.trim()) {
                this.discountError = 'Inserisci un codice sconto valido.';
                return;
            }

            try {
                // Effettua la chiamata API per verificare il codice sconto
                const response = await fetch(`https://elitefitness.utopialabs.it/api/promo/validate/${this.discountCode}?productId=${this.selectedProduct.id}&userId=${this.userId}`, {
                    headers: {
                        Authorization: `Bearer ${this.userToken}`,
                    },
                });

                const result = await response.json();
                const promo = result.promoCode;

                if (!response.ok) {
                    this.showToast(result.message, 'error');
                    return;
                }

                // Verifica se il codice sconto è attivo e valido
                if (promo.status == "error") {
                    this.discountError = promo.message;
                    this.showToast(result.message, 'error');
                    return;
                }

                this.promoCode = promo;
                console.log(this.promoCode)

                this.showToast(result.message, 'success');

            } catch (error) {
                this.discountError = error.message;
                this.discountAmount = 0;  // Reset dello sconto in caso di errore
                this.finalPrice = this.selectedVariationPrice.total;  // Reset del prezzo finale
                this.showToast('Errore nella verifica del codice', 'error');

            }
        },
        toggleSection(section) {
            Object.keys(this.collapsedSections).forEach((key) => {
                this.collapsedSections[key] = true;
            });

            this.collapsedSections[section] = !this.collapsedSections[section];
        },
        selectVariation(variation) {
            this.selectedVariation = variation;
            this.selectedVariationName = variation.variationName;
            this.finalPrice = variation.price;

            if (this.selectedVariationName == 'Annuale' || this.getDuration >= 360) {
                this.annualVariation = true;
            }
            else {
                this.annualVariation = false;
            }


            // Trova e assegna la variazione dello Stop & Go corrispondente
            if (this.stopngoProduct?.variations) {
                console.log("Trova e assegna la variazione dello Stop & Go corrispondente")
                this.selectedStopAndGoVariation = this.stopngoProduct.variations.find(
                    stopGoVariation => stopGoVariation.variationName === this.selectedVariationName
                );
            }
            this.resetDiscountCode();

            this.applyDiscount();

            this.freeTrial = false;
        },
        selectTrainer(trainer) {
            this.selectedTrainerId = trainer.id;
        },
        selectBIATrainer(trainer) {
            this.selectTrainer(trainer);
            this.nextStep();
        },
        goToPayment() {
            this.step = 3;
            this.initializeStripeElements();
            this.isPaymentReady = true; // Abilita il pulsante di pagamento
        },
        nextStep() {
            console.log(this.step);
            // Step 1: Selezione del piano
            if (this.step === 1) {
                console.log("Step 1")
                console.log(this.selectedProduct.type)
                console.log(!this.selectedVariationName, !this.freeTrial, this.selectedProduct.type !== 'BIA')
                if ((!this.selectedVariationName && !this.freeTrial) && this.selectedProduct.type !== 'BIA') {
                    alert("Seleziona un piano per continuare.");
                    return;
                }
                if(this.selectedProduct?.metadata?.type === 'PERSONAL_TRAINING' && !this.selectedPersonalTrainerId){
                    alert("Seleziona un trainer per continuare.");
                    return;
                }
                this.step += 1; // Passa allo step successivo
                console.log(this.userLogged, !this.freeTrial);
                if (this.userLogged && !this.freeTrial) {
                    console.log("this.userLogged && !this.freeTrial")
                    this.step += 1;
                    this.initializeStripeElements();
                    this.isPaymentReady = false;
                }
                console.log(this.step);
                return;
            }

            // Step 2: Inserimento dei dati personali
            if (this.step === 2) {
                console.log("Step 2")
                // Integrazione della logica di nextRegistrationStep
                if (!this.collapsedSections.personalInfo) {
                    this.collapsedSections.personalInfo = true;
                    this.collapsedSections.contactInfo = false;
                } else if (!this.collapsedSections.contactInfo) {
                    this.collapsedSections.contactInfo = true;
                    this.collapsedSections.accountInfo = false;
                } else if (!this.collapsedSections.accountInfo) {
                    // Quando tutte le sezioni sono complete, esegui la registrazione
                    if (!this.userLogged) {
                        this.register()
                            .then(() => {
                                // Dopo la registrazione, passa allo step successivo
                                this.step += 1;
                                console.log(this.step);
                                if (this.step === 3 && !this.freeTrial) {
                                    this.initializeStripeElements();
                                    this.isPaymentReady = false; // Disabilita temporaneamente il pulsante di pagamento
                                }
                            })
                            .catch((error) => {
                                console.error("Errore durante la registrazione:", error);
                                alert("Si è verificato un problema durante la registrazione. Riprova.");
                            });
                        return; // Non continuare fino al completamento della registrazione
                    }

                    // Se l'utente è loggato, passa direttamente allo step successivo
                    this.step += 1;
                    console.log(this.step);
                    return;
                }
                return; // Gestione completa delle sezioni interne di dati
            }

            console.log(this.freeTrial);
            if (!this.freeTrial) {
                // Step 3: Pagamento
                if (this.step === 3) {
                    this.initializeStripeElements();
                    this.isPaymentReady = true; // Abilita il pulsante di pagamento
                }
            }
        },
        async initializeStripeElements() {
            console.log("initializeStripeElements")
            this.$nextTick(() => {
                if (!this.stripe) {
                    console.error("Stripe is not initialized yet.");
                    return;
                }

                if (!this.elements) {
                    this.elements = this.stripe.elements();
                }

                if (!this.cardElement) {
                    // Definisci lo stile per l'elemento della carta
                    const style = {
                        base: {
                            color: 'white',            // Testo bianco
                            fontSize: '20px',          // Testo più grande
                            '::placeholder': {
                                color: 'rgba(255, 255, 255, 0.7)',  // Placeholder bianco, ma più trasparente
                            },
                            textAlign: 'center',       // Testo centrato
                            fontFamily: 'Montserrat, sans-serif', // Imposta il font
                            iconColor: 'white',        // Colore delle icone
                        },
                        invalid: {
                            color: '#ff4d4f',           // Colore rosso per gli input non validi
                            iconColor: '#ff4d4f',
                        },
                    };

                    // Crea e monta l'elemento della carta con lo stile
                    this.cardElement = this.elements.create('card', { style });
                    this.cardElement.mount('#card-element');
                }

                // Creiamo `paymentRequest` come variabile di istanza
                this.paymentRequest = this.stripe.paymentRequest({
                    country: 'IT',
                    currency: 'eur',
                    total: {
                        label: 'Pagamento',
                        amount: Math.round(this.selectedVariationPrice.total * 100),
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });

                // **Verifica se il metodo di pagamento è disponibile**
                this.paymentRequest.canMakePayment().then((canMakePayment) => {
                    if (canMakePayment) {
                        this.isPaymentReady = true;

                        // **Crea il bottone per Apple Pay e Google Pay**
                        this.paymentRequestButton = this.elements.create('paymentRequestButton', {
                            paymentRequest: this.paymentRequest,
                        });

                        this.paymentRequestButton.mount('#payment-request-button-container');

                        // **Gestisci il pagamento con Apple Pay / Google Pay**
                        this.paymentRequest.on('paymentmethod', async (event) => {
                            try {
                                const response = await fetch('https://elitefitness.utopialabs.it/api/payment/create', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${this.userToken}`,
                                    },
                                    body: JSON.stringify({
                                        productId: this.selectedProductId,
                                        variationName: this.selectedVariationName,
                                        newUser: this.userLogged ? null : this.user,
                                        promoCode: this.discountCode,
                                        registerUser: !this.userLogged,
                                        email: this.userEmail,
                                        token: this.userToken,
                                    }),
                                });

                                const { client_secret } = await response.json();

                                const { error } = await this.stripe.confirmCardPayment(client_secret, {
                                    payment_method: event.paymentMethod.id,
                                });

                                if (error) {
                                    event.complete('fail');
                                    alert(error.message);
                                } else {
                                    event.complete('success');
                                    this.$router.push('/success');
                                }
                            } catch (error) {
                                event.complete('fail');
                                console.error(error);
                                alert('Errore durante il pagamento.');
                            }
                        });
                    }
                });
                

                // Abilita il pulsante di pagamento dopo che i dati della carta sono pronti
                this.isPaymentReady = true;
            });
        },
        async handleFreeTrial() {
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/freeTrial?serviceId=' + this.selectedProduct.serviceId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                });

                // Vado alla pagina /success se la richiesta va a buon fine, ossia "status":"ok"
                const data = await response.json();

                console.log(data);

                if (data.status == 'ok') {
                    this.$router.push('/success');
                } else {
                    alert('Non è stato possibile iniziare la prova gratuita.');
                }


            } catch (error) {
                console.error(error);
                alert('Non è stato possibile iniziare la prova gratuita.');
            }
        },
        async handleCardPayment() {
            try {
                if (!this.stripe || !this.cardElement) {
                    alert("Stripe or card element is not ready.");
                    return;
                }

                const selectedVariation = this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName);
                if (!selectedVariation) {
                    console.error("Selected variation not found");
                    return;
                }

                const paymentRequest = this.createPaymentRequest();

                const response = await fetch('https://elitefitness.utopialabs.it/api/payment/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.userToken}`,
                    },
                    body: JSON.stringify(paymentRequest),
                });

                if (!response.ok) {
                    throw new Error('Failed to create payment intent');
                }

                const { client_secret } = await response.json();

                const { error, paymentIntent } = await this.stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.userFullName,
                            email: this.userEmail,
                        },
                    },
                });

                if (error) {
                    alert(error.message);
                } else if (paymentIntent.status === 'succeeded') {
                    this.$router.push('/success');
                }
            } catch (error) {
                console.error(error);
                alert('An error occurred during payment.');
            }
        },
        isUserDataValid() {
            return this.user.email && this.user.password && this.user.firstName && this.user.lastName;
        },
        selectPaymentMethod(method) {
            this.selectedPayment = method.value;

            // Aggiorna la logica del pulsante
            if (method.value == "CARD") {
                this.initializeStripeElements(); // Configura Stripe
            }

            this.isPaymentReady = true;
        },
        handlePayment() {
            if (this.userLogged && this.freeTrial) {
                this.handleFreeTrial();
            }
            else {
                if (this.selectedPayment === "CARD") {
                    this.handleCardPayment(); // Logica per pagamento con carta
                } else if (this.selectedPayment === "CASH") {
                    this.handleCashPayment(); // Logica per pagamento in contanti
                }
            }
        },
        createPaymentRequest(){
            const upsells = [];
            const additionals = [];

            if (this.requiresRegistration && this.registrationProduct && this.annualVariation == false) {
                upsells.push(this.registrationProduct.id);
            }
            if (this.requiresMembership && this.membershipProduct) {
                upsells.push(this.membershipProduct.id);
            }
            if (this.requiresStopAndGo && this.stopngoProduct) {
                upsells.push(this.stopngoProduct.id)
            }
            if (this.requiresBIA && this.biaProduct) {
                upsells.push(this.biaProduct.id);
            }
            if (this.requiresMandatoryUpsell && this.mandatoryProduct) {
                upsells.push(this.mandatoryProduct.id);
            }

            let paymentRequest = {
                productId: this.selectedProductId,
                variationName: this.selectedVariationName,
                newUser: this.userLogged ? null : this.user,
                promoCode: this.discountCode,
                registerUser: !this.userLogged,
                email: this.userEmail,
                token: this.userToken,
                upsells, // Aggiungi i prodotti di upsell
            };

            if (this.selectedTrainerId) {
                additionals.push({
                    productId: this.biaProduct ? this.biaProduct.id : (this.selectedProductId),
                    additionals: {
                        trainerId: this.selectedTrainerId
                    }
                });
            }

            if (this.selectedTargetTrainerId) {
                additionals.push({
                    productId: this.selectedProductId,
                    additionals: {
                        targetTrainerId: this.selectedTargetTrainerId
                    }
                });
            }

            if (this.selectedPersonalTrainerId) {
                additionals.push({
                    productId: this.selectedProductId,
                    additionals: {
                        personalTrainerId: this.selectedPersonalTrainerId
                    }
                });
            }

            if (this.selectedMandatoryVariation) {
                additionals.push({
                    productId: this.mandatoryProduct.id,
                    additionals: {
                        mandatoryVariation: this.selectedMandatoryVariation.variationName
                    }
                });
            }

            paymentRequest = {
                ...paymentRequest,
                additionals: additionals
            };

            return paymentRequest;
        },
        handleCashPayment() {
            /*
            const selectedVariation = this.selectedProduct.variations
                ? this.selectedProduct.variations.find(variation => variation.variationName === this.selectedVariationName)
                : null;
            */
            // Determina i prodotti di upsell necessari
            
            const paymentRequest = this.createPaymentRequest();

            fetch('https://elitefitness.utopialabs.it/api/payment/cash/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.userToken}`,
                },
                body: JSON.stringify(paymentRequest),
            })
                .then(response => {
                    if (!response.ok) {
                        return response.text().then(errorText => {
                            throw new Error(`Errore durante il pagamento in contanti: ${errorText}`);
                        });
                    }
                    return response.text();
                })
                .then(result => {
                    console.log(result);
                    alert('Pagamento in contanti registrato con successo!');

                    // Reindirizza alla pagina di successo
                    this.$router.push('/success');
                })
                .catch(error => {
                    console.error(error);
                    alert('Si è verificato un errore durante il pagamento in contanti.');
                });
        },
        async register() {
            if (this.emailError) {
                alert("L'email non è valida o è già in uso.");
                throw new Error("Errore di validazione email");
            }

            if (this.passwordError) {
                alert("La password non soddisfa i requisiti minimi di sicurezza.");
                throw new Error("Errore di validazione password");
            }

            this.isLoading = true; // Attiva il caricamento
            try {
                // Registrazione utente
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.user),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Errore durante la registrazione');
                }

                console.log('Registrazione completata.');

                // Login automatico
                const loginResponse = await fetch('https://elitefitness.utopialabs.it/api/auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                        password: this.user.password,
                    }),
                });

                if (!loginResponse.ok) {
                    const loginErrorData = await loginResponse.json();
                    throw new Error(loginErrorData.message || 'Errore durante il login automatico');
                }

                const loginData = await loginResponse.json();
                console.log('Login completato:', loginData);

                this.userEmail = this.user.email;
                this.userToken = loginData.token;
                // Salva il token e l'email dell'utente
                localStorage.setItem('userEmail', loginData.email);
                localStorage.setItem('userToken', loginData.token);

                // Recupera il profilo utente
                const profileResponse = await fetch('https://elitefitness.utopialabs.it/api/users/profile', {
                    headers: {
                        Authorization: `Bearer ${loginData.token}`,
                    },
                });

                if (!profileResponse.ok) {
                    throw new Error('Errore nel recupero del profilo utente');
                }

                const userProfile = await profileResponse.json();
                console.log('Profilo utente recuperato:', userProfile);

                // Aggiorna lo stato utente
                this.userLogged = true;
                this.userFullName = userProfile.personalInfo.fullName.replace(/\b\w/g, (c) => c.toUpperCase());

                // Restituisci il token per eventuali chiamate successive
                return loginData.token;

            } catch (error) {
                console.error('Errore durante la registrazione, login o recupero del profilo:', error);
                alert(error.message || 'Si è verificato un errore. Riprova più tardi.');
                throw error;
            } finally {
                this.isLoading = false; // Disattiva il caricamento
            }
        },
        updateDateOfBirth(date) {
            console.log('Data di nascita aggiornata:', date)
        },
        toggleFreeTrialMode() {
            this.freeTrial = !this.freeTrial;
            if (this.freeTrial) {
                this.selectedVariationName = null;
                this.selectedVariation = null;
            }
        },
        generateFreeTierMessage(center) {
            const freeTierTimeSlots = center.freeTierTimeSlots;
            if (!freeTierTimeSlots || freeTierTimeSlots.length === 0) {
                return " non è disponibile in nessuna fascia oraria.";
            }

            let message = " è disponibile ";
            let groupedSlots = [];
            let tempGroup = [freeTierTimeSlots[0]];

            for (let i = 1; i < freeTierTimeSlots.length; i++) {
                const prev = freeTierTimeSlots[i - 1];
                const current = freeTierTimeSlots[i];

                if (prev.startTime === current.startTime && prev.endTime === current.endTime) {
                    tempGroup.push(current);
                } else {
                    groupedSlots.push(tempGroup);
                    tempGroup = [current];
                }
            }
            groupedSlots.push(tempGroup);

            const dayTranslation = {
                "MONDAY": "lunedì",
                "TUESDAY": "martedì",
                "WEDNESDAY": "mercoledì",
                "THURSDAY": "giovedì",
                "FRIDAY": "venerdì",
                "SATURDAY": "sabato",
                "SUNDAY": "domenica"
            };

            let slotMessages = groupedSlots.map(group => {
                let days = group.map(slot => dayTranslation[slot.dayOfWeek]);
                let startTime = group[0].startTime.replace(":00", "");
                let endTime = group[0].endTime.replace(":00", "");
                return `dal ${days[0]} al ${days[days.length - 1]}, dalle ${startTime} alle ${endTime}`;
            });

            message += slotMessages.join("; e ") + ".";
            return message;
        },
        generateFreeTierCourse(service) {
            const freeTierTimeSlots = service.timeSlots;
            if (!freeTierTimeSlots || freeTierTimeSlots.length === 0) {
                return " non è disponibile in nessuna fascia oraria.";
            }

            let message = " è disponibile ";
            let groupedSlots = [];
            let tempGroup = [freeTierTimeSlots[0]];

            for (let i = 1; i < freeTierTimeSlots.length; i++) {
                const prev = freeTierTimeSlots[i - 1];
                const current = freeTierTimeSlots[i];

                if (prev.startTime === current.startTime && prev.endTime === current.endTime && 
                    new Date(prev.dayOfWeek).getDay() + 1 === new Date(current.dayOfWeek).getDay()) {
                    tempGroup.push(current);
                } else {
                    groupedSlots.push(tempGroup);
                    tempGroup = [current];
                }
            }
            groupedSlots.push(tempGroup);

            const dayTranslation = {
                "MONDAY": "Lunedì",
                "TUESDAY": "Martedì",
                "WEDNESDAY": "Mercoledì",
                "THURSDAY": "Giovedì",
                "FRIDAY": "Venerdì",
                "SATURDAY": "Sabato",
                "SUNDAY": "Domenica"
            };

            let slotMessages = groupedSlots.map(group => {
                let days = group.map(slot => dayTranslation[slot.dayOfWeek]);
                let startTime = group[0].startTime.replace(":00", "");
                let endTime = group[0].endTime.replace(":00", "");
                return `il ${days.join(", ")}, dalle ${startTime} alle ${endTime}`;
            });

            message += slotMessages.join(", ") + ".";
            return message;
        }
    },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

h2,
h3,
h4 {
    font-family: 'Montserrat', sans-serif;
}

.step-header {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: left;
    margin-bottom: 40px;
}

.step-circle {
    font-family: 'Montserrat', sans-serif;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    background-color: white;
    color: #e83539;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.step-title {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.step-content {
    margin-bottom: 40px;
    margin-left: 0;
    /* Nessun margine a sinistra su dispositivi piccoli */
}

@media (min-width: 768px) {
    .step-content {
        margin-left: 65px;
        /* Applica margin-left solo su tablet e dispositivi più grandi */
    }
}


.duration-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media (max-width: 767px) {
    .duration-grid {
        grid-template-columns: 1fr;
    }
}

.trainer-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.duration-option {
    font-family: 'Montserrat', sans-serif;
    background-color: #2e348c;
    border: 2px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
}

/** Mobile */
@media (max-width: 767px) {
    .duration-option {
        width: 100%;
        padding: 5px;
    }
}

.trainer-option {
    font-family: 'Montserrat', sans-serif;
    background-color: #2e348c;
    border: 2px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    position: relative;
    width: 100%;
    padding: 5px;
}

.duration-option.selected {
    border-color: #e83539;
    background-color: #292D7D;
}

.trainer-option.selected {
    border-color: #e83539;
    background-color: #292D7D;
}

.duration-title {
    font-size: 1.2rem;
    /* Font-size su dispositivi mobili */
    font-weight: 600;
}

.trainer-title{
    font-size: 1rem;
    font-weight: 500;
}

@media (min-width: 768px) {
    .duration-title {
        font-size: 1.5rem;
    }
}

.duration-price {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
}

.checkmark {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #e83539;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

h4 {
    margin: 0px;
    padding: 0px;
}

h2,
h3,
p,
ul {
    color: white;
}

ul {
    padding: 0;
}

.terms {
    font-size: 0.9rem;
    text-align: center;
    margin: 20px 0;
}

.terms a {
    color: white;
    text-decoration: underline;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    max-width: 100% !important;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.btn-custom:hover {
    background-color: #cc2e31;
}

.highlight {
    font-weight: 600;
    color: white;
    text-decoration: underline;
}

form input,
#card-element {
    color: black;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

form select {
    color: white;
    margin-bottom: 15px;
    height: 60px;
    padding: 0 10px;
    border-radius: 0;
}

select::after {
    color: white;
}

.form-select {
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
    border-radius: 0px;
    width: 100%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 5.646a.5.5 0 0 1 .708 0L8 11.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-size: 24px;
}

.form-select-selected{
    border-color: #e83539 !important;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    color: white;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.discount {
    color: rgb(19, 19, 19) !important;
    background-color: white;
    border-radius: 0px;
    height: 45px;
}

.custom-placeholder::-webkit-input-placeholder {
    /* Chrome, Safari, Edge */
    color: #232323;
}

.custom-placeholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #232323;
}

.custom-placeholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #232323;
}

.custom-placeholder::placeholder {
    /* Standard */
    color: #232323;
}


.white-container {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.207);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

.white-container:hover {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.352);
    border-radius: 0px;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 25px;
}

/* Stili per il layout di pagamento */
.payment-method-section {
    margin-bottom: 20px;
}

.payment-grid {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.payment-option {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    flex-grow: 1;
    font-family: "Montserrat", sans-serif;
}

.payment-option.selected {
    border-color: #e83539;
}

.payment-info {
    margin-top: 10px;
    font-size: 1.2rem;
    color: white;
    background-color: #e83539;
    padding: 15px;
}

.complete-payment-btn {
    background-color: #b2003b;
    width: 100%;
    margin: 20px auto;
    padding: 10px;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.complete-payment-btn:disabled {
    background-color: #e83539;
    cursor: not-allowed;
}

.checkbox-container {
    display: flex;
    align-items: center;
    /* Allinea verticalmente al centro */
    gap: 15px;
    /* Spazio tra checkbox e testo */
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    border-radius: 0px;
    border: 2px solid white;
    margin-top: 20px;
}

.upsell-checkbox-container {
    display: flex;
    align-items: center;
    /* Allinea verticalmente al centro */
    gap: 15px;
    /* Spazio tra checkbox e testo */
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    border-radius: 0px;
}

.custom-checkbox {
    appearance: none;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    background-color: #2e348c;
    border: 2px solid rgba(255, 255, 255, 0.144) !important;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

.custom-checkbox:checked {
    background-color: #e83539;
}

.custom-checkbox:checked::before {
    content: '\f00c';
    /* Codice Unicode per l'icona di Font Awesome (fa-check) */
    font-family: 'Font Awesome 5 Free';
    /* Assicurati di avere Font Awesome caricato */
    font-weight: 900;
    color: white;
    text-align: center;
    font-size: 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centra verticalmente e orizzontalmente */
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-container {
    display: flex;
    flex-direction: column;
    /* Dispone il titolo sopra e la descrizione sotto */
}

.custom-label {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.custom-description {
    margin-top: 5px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    /* Colore bianco trasparente */
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0px !important;
}

@media (max-width: 767px) {
    .back-button {
        display: none !important;
    }

    .user-info-nav {
        display: none !important;
    }
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
    margin: 20px;
    padding-bottom: 10px;
    /* Assicurati che sia visibile sopra gli altri elementi */
}

.back-button i {
    font-size: 24px;
    color: white;
    transition: transform 0.2s ease;
}

.back-button:hover {
    transform: scale(1.05);
    border-bottom: 2px solid #e83539;
    /* Leggera animazione al passaggio del mouse */
}

.user-info-nav {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
    margin: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-avatar {
    width: 30px;
    height: 30px;
    border: 2px solid #e83539;
    /* Bordo rosso */
    border-radius: 50%;
    /* Cerchio perfetto */
    background-color: #ffffff34;
    color: white;
    text-align: center;
    /* Colore di sfondo (può essere sostituito con l'immagine dell'avatar) */
}

.user-name {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: white;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #333892;
    /* Blu coerente con l'app */
    color: white;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    min-width: 150px;
    display: flex;
    flex-direction: column;
}

.menu-item {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: white;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.menu-item:hover {
    background-color: #1c1f60;
    /* Blu più scuro per hover */
    border-radius: 4px;
}

.loading-dots {
    display: inline-flex;
    gap: 2px;
}

.loading-dots span {
    animation: blink 1.4s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {

    0%,
    100% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }
}

.form-group {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(41, 45, 125);
    padding: 5px;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
}

:deep(.dp__input) {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    height: 60px;
    border-radius: 0;
}

:deep(.dp__input::placeholder) {
    color: rgba(255, 255, 255, 0.6);
}

:deep(.dp__input:focus) {
    border-color: #e83539;
    box-shadow: 0 0 0 0.25rem rgba(232, 53, 57, 0.25);
}

:deep(.dp__main) {
    font-family: 'Montserrat', sans-serif;
}

:deep(.dp__active) {
    background-color: #e83539 !important;
}

/* Contenitore principale dei toast */
.toast-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    /* Evita interazioni con altri elementi */
}

/* Stile base dei toast */
.toast {
    margin: 10px 0;
    padding: 15px 20px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    min-width: 250px;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: all;
    /* Permette interazioni solo con il toast */
    transition: opacity 0.5s ease-in-out;
}

/* Tipi di toast */
.toast-success {
    background-color: #4CAF50;
    /* Verde per successi */
}

.toast-error {
    background-color: #f44336;
    /* Rosso per errori */
}

.toast-warning {
    background-color: #FF9800;
    /* Arancione per avvisi */
}

.toast-info {
    background-color: #2196F3;
    /* Blu per informazioni */
}

/* Effetti di comparsa/scomparsa */
.toast.fade-out {
    opacity: 0;
}

.alert-primary {
    background: #ffffff2e;
    color: white;
    border-radius: 0px;
}

.alert-danger {
    background: #e83539;
    color: white;
    border-radius: 0px;
    border: 0px;
}

.strike {
    text-decoration: line-through;
}

.upsell-box {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    border: 2px solid white;
}

.mandatory-upsell-box{
    border: 2px solid #e83539;
}

.custom-select {
    background-color: rgb(63 66 138);
    /* Cambia il colore di sfondo del dropdown */
    color: white;
    /* Cambia il colore del testo */
}

.custom-select option {
    background-color: rgb(63 66 138);
    /* Cambia il colore di sfondo delle opzioni */
    color: white;
    /* Cambia il colore del testo delle opzioni */
}

.discount-item-description {
    margin-bottom: 5px;
    border-bottom: 1px solid red;
    text-align: end;
}

@media (min-width: 1200px) {
    .border-left-white {
        border-left: 3px solid;
        border-color: white;
    }
}

@media (max-width: 767px) {
    .border-top-white {
        border-top: 3px solid;
        border-color: white;
        padding-top: 30px;
    }
}
</style>